import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import YouTube, { YouTubeEvent, YouTubePlayer } from 'react-youtube';
import { AnalyticsEvent, trackCustomEvent } from '../../utils/analytics';

export const YOUTUBE_MODAL_ID = 'youtube-embed-modal';

interface YoutubeModalProps {
  youtubeId: string | null;
}

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
};

export interface YoutubeModalRef {
  openModal: () => void;
}

export const YoutubeModal = forwardRef(
  ({ youtubeId }: YoutubeModalProps, ref?: React.Ref<YoutubeModalRef>) => {
    const youtubeRef = useRef<YouTubePlayer>(null);
    const checkboxRef = useRef<HTMLInputElement>(null);

    const openModal = useCallback(() => {
      if (checkboxRef.current?.checked !== undefined) {
        checkboxRef.current.checked = true;
        youtubeRef.current?.playVideo();
        trackCustomEvent({
          category: 'Video player',
          action: AnalyticsEvent.PlayVideo,
          label: youtubeId || '',
        });
      }
    }, [youtubeId]);

    const onReady = useCallback((event: YouTubeEvent) => {
      youtubeRef.current = event.target;
    }, []);

    const onClose = useCallback(() => {
      youtubeRef.current?.pauseVideo();
      if (checkboxRef.current?.checked !== undefined) {
        checkboxRef.current.checked = false;
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        openModal,
      }),
      [openModal]
    );

    return (
      <>
        <input
          ref={checkboxRef}
          type="checkbox"
          id={YOUTUBE_MODAL_ID}
          className="modal-toggle"
        />
        <div className="modal">
          <div className="modal-box relative rounded-none w-full max-w-full h-full max-h-full p-0">
            <button
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>

            {youtubeId && (
              <YouTube
                videoId={youtubeId}
                className="w-ful h-full"
                opts={opts}
                onReady={onReady}
              />
            )}
          </div>
        </div>
      </>
    );
  }
);

YoutubeModal.displayName = 'YoutubeModal';
