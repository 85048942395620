import './src/styles/global.css';

import { QueryClient, QueryClientProvider } from 'react-query';

import { OpenCollectiveContextProvider } from './src/context/OpenCollectiveContext';
import { TimezoneContextProvider } from './src/context/TimezoneContext';
import { YoutubeContextProvider } from './src/context/YoutubeContext';
import { RecentDonations } from './src/components/RecentDonations';
import { GatsbyBrowser } from 'gatsby';

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
);

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  if (props.uri === '/video-overlay') {
    return (
      <OpenCollectiveContextProvider refetchInterval={1000 * 60}>
        {element}
      </OpenCollectiveContextProvider>
    );
  }

  return (
    <TimezoneContextProvider>
      <OpenCollectiveContextProvider>
        <YoutubeContextProvider>
          {element}
          <RecentDonations />
        </YoutubeContextProvider>
      </OpenCollectiveContextProvider>
    </TimezoneContextProvider>
  );
};

if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}
