exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-code-of-conduct-md": () => import("./../../../src/pages/markdown/code-of-conduct.md" /* webpackChunkName: "component---src-pages-markdown-code-of-conduct-md" */),
  "component---src-pages-markdown-press-release-md": () => import("./../../../src/pages/markdown/press-release.md" /* webpackChunkName: "component---src-pages-markdown-press-release-md" */),
  "component---src-pages-markdown-why-these-organizations-md": () => import("./../../../src/pages/markdown/why-these-organizations.md" /* webpackChunkName: "component---src-pages-markdown-why-these-organizations-md" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-video-overlay-tsx": () => import("./../../../src/pages/video-overlay.tsx" /* webpackChunkName: "component---src-pages-video-overlay-tsx" */)
}

