import { EmojiImages } from '../constants';
import { Emoji } from '../types';

export const randomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const getRandomEmoji = () => {
  const emojis = Object.values(Emoji);
  return emojis[~~(Math.random() * emojis.length)] as Emoji;
};

export const getRandomImage = () => {
  const emojis = Object.values(EmojiImages);
  return emojis[~~(Math.random() * emojis.length)];
};
