import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { YoutubeModal, YoutubeModalRef } from '../components/YoutubeModal';

type YoutubeContextType = {
  openVideo: (youtubeId: string) => void;
};

export const YoutubeContext = createContext<YoutubeContextType | null>(null);

interface YoutubeContextProviderProps {
  children: React.ReactNode;
}

export const YoutubeContextProvider = ({
  children,
}: YoutubeContextProviderProps) => {
  const youtubeRef = useRef<YoutubeModalRef>(null);
  const [youtubeId, setYoutubeId] = useState<string | null>(null);

  const openVideo = useCallback((nextYoutubeId: string) => {
    setYoutubeId(nextYoutubeId);
    youtubeRef.current?.openModal();
  }, []);

  const contextValue = useMemo(
    () => ({
      openVideo,
    }),
    [openVideo]
  );

  return (
    <YoutubeContext.Provider value={contextValue}>
      {children}

      <YoutubeModal ref={youtubeRef} youtubeId={youtubeId} />
    </YoutubeContext.Provider>
  );
};

export const useYoutubeContext = (): YoutubeContextType => {
  const context = useContext(YoutubeContext);

  if (!context) {
    return {
      openVideo: () => {},
    };
  }

  return context;
};
