import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import spacetime from 'spacetime';
import { useTransition, animated } from 'react-spring';
import {
  Contribution,
  useOpenCollectiveContext,
} from '../../context/OpenCollectiveContext';
import { getRandomImage, randomNumber } from '../../utils';
import { formatPrice } from '../../utils/priceFormatter';

const NOTIFICATION_DURATION = 10000;
const TIMEOUTS = [5000, 20000, 30000, 50000, 80000];

export const RecentDonations = () => {
  const { contributions } = useOpenCollectiveContext();

  const indexRef = useRef(0);
  const timeoutRef = useRef<NodeJS.Timer>();
  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);
  const [items, setItems] = useState<
    (Contribution & { emojis: ReactNode[] })[]
  >([]);

  const transitions = useTransition(items, {
    from: { opacity: 0, height: 0, life: '100%' },
    keys: (item) => item.createdAt,
    enter: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ opacity: 1, height: refMap.get(item).offsetHeight });
      await next({ life: '0%' });
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    onRest: (_result, _ctrl, item) => {
      setItems((state) => state.filter((i) => i.createdAt !== item.createdAt));
      showNextDonation();
    },
    config: (_item, _index, phase) => (key) =>
      phase === 'enter' && key === 'life'
        ? { duration: NOTIFICATION_DURATION }
        : { tension: 125, friction: 20, precision: 0.1 },
  });

  const showNextDonation = useCallback(() => {
    const timeout = TIMEOUTS[Math.min(indexRef.current, TIMEOUTS.length)];
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setItems([
        {
          ...contributions[indexRef.current],
          emojis: Array.from({ length: Math.floor(randomNumber(1, 3)) }).map(
            (_, index) => {
              const isTop = randomNumber(-1, 1) > 0;
              const multiplier = isTop ? -1 : 1;
              return (
                <img
                  key={index}
                  src={getRandomImage()}
                  className="absolute z-10 text-xl h-5"
                  style={{
                    ...(randomNumber(-1, 1) > 0
                      ? { left: 0 }
                      : { right: '32px' }),
                    ...(isTop ? { top: 0 } : { bottom: 0 }),
                    transform: `translate(${randomNumber(
                      -80,
                      -10
                    )}%, ${randomNumber(multiplier * 80, multiplier * 50)}%)`,
                  }}
                />
              );
            }
          ),
        },
      ]);
      indexRef.current =
        indexRef.current < contributions.length - 1 ? indexRef.current + 1 : 0;
    }, timeout);
  }, [contributions]);

  useEffect(() => {
    if (contributions.length) {
      showNextDonation();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [contributions, showNextDonation]);

  if (contributions.length === 0) {
    return null;
  }

  return (
    <div className="fixed z-50 w-auto bottom-8 right-8 left-8 flex flex-col items-end">
      {transitions(({ life, ...style }, item) => (
        <animated.div className="relative" style={style}>
          <div
            ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
            className="relative py-2 badge badge-primary badge-lg h-auto drop-shadow"
          >
            <div className="flex flex-col">
              <span>{`${item.fromAccount.name} donated ${formatPrice(
                item.amount.value
              )}`}</span>
              <span className="-mt-1 text-xs text-right opacity-70">
                {spacetime(item.createdAt).fromNow().rounded}
              </span>
            </div>

            <svg
              className="ml-2 inline-block w-4 h-4 stroke-current cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                if (cancelMap.has(item) && life.get() !== '0%') {
                  cancelMap.get(item)();
                }
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            {item.emojis.map((emoji) => emoji)}
          </div>
        </animated.div>
      ))}
    </div>
  );
};
