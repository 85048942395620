export { OutboundLink } from 'gatsby-plugin-google-gtag';

export enum AnalyticsEvent {
  Click = 'click',
  CTA = 'cta',
  OpenModal = 'open_modal',
  SelectDonation = 'select_donation',
  MakeAnotherDonation = 'make_another_donation',
  OpenPaymentFlow = 'open_payment_flow',
  SubscribeToEmails = 'subscribe_to_emails',
  ChangeTimezone = 'change_timezone',
  PlayVideo = 'play_video',
  OpenSession = 'open_session',
  CopyCryptoAddress = 'copy_crypto_address',
}

export const trackCustomEvent = ({
  action,
  ...data
}: {
  category: string;
  action: AnalyticsEvent;
  label?: string;
  value?: number | string;
}) => {
  window?.gtag?.('event', action, { ...data });
};
