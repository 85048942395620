import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

export enum Stream {
  'Android' = 'android',
  'Web' = 'web',
  'AI' = 'ai',
}

export enum Social {
  'Twitter' = 'twitter',
  'Linkedin' = 'linkedin',
  'TikTok' = 'tiktok',
}

export enum Emoji {
  'HeartBlue' = 'HeartBlue',
  'HeartYellow' = 'HeartYellow',
  'Flag' = 'Flag',
  'Hand' = 'Hand',
}

export interface TeamJSON {
  name: string;
  image: string;
  group: string;
  social: {
    type: Social;
    url: string;
  };
}

export interface Team extends Omit<TeamJSON, 'image'> {
  image: IGatsbyImageData;
}

export interface SpeakerJSON {
  speakerId: string;
  name: string;
  image: string;
  title: string;
  stream: Stream;
  isKeynote?: boolean;
  social: {
    type: Social;
    url: string;
  };
}

export interface SessionJSON {
  sessionId: string;
  title: string;
  abstract?: string;
  speakerIds: string[];
}

export interface ScheduleDayJSON {
  date: string;
  streams: {
    streamId: Stream;
    name: string;
  }[];
  timeSlots: {
    start: string;
    end: string;
    sessionIds: string[];
  }[];
}

export interface Speaker extends Omit<SpeakerJSON, 'stream' | 'image'> {
  image: IGatsbyImageData;
}
export interface Session extends Omit<SessionJSON, 'speakerIds'> {
  speakers: Speaker[];
}
export interface ScheduleDay extends Omit<ScheduleDayJSON, 'timeSlots'> {
  timeSlots: (Omit<ScheduleDayJSON['timeSlots'], 'sessionIds'> & {
    start: string;
    end: string;
    sessions: (Session | null)[];
  })[];
}

export interface Cryptocurrency {
  symbol: string;
  name: string;
  address: string;
  logo: React.ReactNode;
}
