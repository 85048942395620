import { useEffect, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T | null) => {
  const [value, setValue] = useState<T | null>(() => {
    const item = typeof window !== 'undefined' && localStorage?.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  useEffect(() => {
    localStorage?.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};
