import { createContext, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import spacetime from 'spacetime';
import { useLocalStorage } from '../hooks/useLocalStorage';

const OPEN_COLLECTIVE_ENDPOINT =
  'https://api.opencollective.com/graphql/v2/691b28501b68d11004d864d3cdd242f654635588';
const EVENT_SLUG = 'devfest-for-ukraine-93217149';

const EVENT_QUERY = `
  query event {
    event(slug: "${EVENT_SLUG}") {
      stats {
          totalNetAmountReceived {
              value
          }
      }
      transactions(limit: 10, type: CREDIT) {
        totalCount
        nodes {
          amount {
            value
          }
          createdAt
          fromAccount {
              name
          }
        }
      }
    }
  }
`;

export interface Contribution {
  amount: {
    value: number;
  };
  createdAt: string;
  fromAccount: {
    name: string;
  };
}

interface OpenCollectiveStatistic {
  totalNetAmountReceived: number;
  totalCount: number;
}

type OpenCollectiveData = {
  event: {
    stats: {
      totalNetAmountReceived: {
        value: number;
        currency: string;
      };
    };
    transactions: {
      totalCount: number;
      nodes: Contribution[];
    };
  };
};

type OpenCollectiveContextType = {
  statistic: OpenCollectiveStatistic | null | undefined;
  contributions: Contribution[];
  isLoading: boolean;
  isFetching: boolean;
  error: Error | null;
};

export const OpenCollectiveContext =
  createContext<OpenCollectiveContextType | null>(null);

interface OpenCollectiveContextProviderProps {
  children: React.ReactNode;
  refetchInterval?: number | false;
}

export const OpenCollectiveContextProvider = ({
  children,
  refetchInterval = false,
}: OpenCollectiveContextProviderProps) => {
  const [cached, setCached] = useLocalStorage<OpenCollectiveStatistic>(
    'open_collective',
    null
  );
  const [contributions, setContributions] = useState<Contribution[]>([]);

  const { isLoading, error, data, isFetching } =
    useQuery<OpenCollectiveStatistic>(
      'eventData',
      () => {
        return fetch(OPEN_COLLECTIVE_ENDPOINT, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ query: EVENT_QUERY }),
        })
          .then((response) => {
            if (response.status >= 400) {
              throw new Error('Error fetching data');
            } else {
              return response.json();
            }
          })
          .then(({ data }: { data: OpenCollectiveData }) => {
            const statistic = {
              totalNetAmountReceived:
                data.event.stats.totalNetAmountReceived.value,
              totalCount: data.event.transactions.totalCount,
            };
            const contributions = data.event.transactions.nodes
              .filter(
                (node) =>
                  node.amount.value >= 100 ||
                  spacetime(node.createdAt).isAfter(
                    spacetime().subtract(2, 'hour')
                  ) ||
                  node.fromAccount.name !== 'Guest'
              )
              .map((node) => ({
                ...node,
                fromAccount: {
                  name: node.fromAccount.name.split(' ')[0],
                },
              }));
            setCached(statistic);
            setContributions(contributions);
            return statistic;
          });
      },
      {
        refetchOnWindowFocus: false,
        refetchInterval,
      }
    );

  const contextValue = useMemo(
    () => ({
      statistic: data || cached,
      contributions,
      isLoading,
      isFetching,
      error: error as any,
    }),
    [isLoading, isFetching, data, cached, contributions, error]
  );

  return (
    <OpenCollectiveContext.Provider value={contextValue}>
      {children}
    </OpenCollectiveContext.Provider>
  );
};

export const useOpenCollectiveContext = (): OpenCollectiveContextType => {
  const context = useContext(OpenCollectiveContext);

  if (!context) {
    return {
      statistic: undefined,
      contributions: [],
      isLoading: false,
      isFetching: false,
      error: null,
    };
  }

  return context;
};
