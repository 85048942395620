import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import spacetime from 'spacetime';
import soft from 'timezone-soft';
import { useLocalStorage } from '../hooks/useLocalStorage';
import allTimezones from '../utils/timezone-list';

export interface Timezone {
  value: string;
  label: string;
  abbrev?: string;
  altName?: string;
  offset?: number;
}

type TimezoneContextType = {
  timezones: Timezone[] | null;
  selectedTimezone: Timezone | undefined;
  setTimezone: (timezone: Timezone | string) => void;
};

export const TimezoneContext = createContext<TimezoneContextType | null>(null);

interface TimezoneContextProviderProps {
  children: React.ReactNode;
}

export const TimezoneContextProvider = ({
  children,
}: TimezoneContextProviderProps) => {
  const [timezones, setTimezones] = useState<Timezone[]>([]);
  const [selectedTimezoneCached, setSelectedTimezoneCached] =
    useLocalStorage<string>('selected-timezone', null);

  const findFuzzyTz = useCallback(
    (zone: string): Timezone | undefined => {
      let currentTime = spacetime.now('GMT');
      try {
        currentTime = spacetime.now(zone);
      } catch (err) {
        return;
      }
      return timezones
        .filter(
          (tz: Timezone) => tz.offset === currentTime.timezone().current.offset
        )
        .map((tz: Timezone) => {
          let score = 0;
          if (
            currentTime.timezones[tz.value.toLowerCase()] &&
            !!currentTime.timezones[tz.value.toLowerCase()].dst ===
              currentTime.timezone().hasDst
          ) {
            if (
              tz.value
                .toLowerCase()
                .indexOf(
                  currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)
                ) !== -1
            ) {
              score += 8;
            }
            if (
              tz.label
                .toLowerCase()
                .indexOf(
                  currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)
                ) !== -1
            ) {
              score += 4;
            }
            if (
              tz.value
                .toLowerCase()
                .indexOf(
                  currentTime.tz.substring(0, currentTime.tz.indexOf('/'))
                )
            ) {
              score += 2;
            }
            score += 1;
          } else if (tz.value === 'GMT') {
            score += 1;
          }
          return { tz, score };
        })
        .sort((a, b) => b.score - a.score)
        .map(({ tz }) => tz)[0];
    },
    [timezones]
  );

  const parseTimezone = useCallback(
    (zone: Timezone | string): Timezone | undefined => {
      if (typeof zone === 'object' && zone.value && zone.label) return zone;
      if (typeof zone === 'string') {
        const regularLookup = timezones.find((tz) => tz.value === zone);
        if (regularLookup) return regularLookup;
        if (zone.indexOf('/') !== -1) {
          return findFuzzyTz(zone);
        }
      } else if (zone.value && !zone.label) {
        return timezones.find((tz) => tz.value === zone.value);
      }
    },
    [findFuzzyTz, timezones]
  );

  useEffect(() => {
    setTimezones(
      Object.entries(allTimezones)
        .reduce<Timezone[]>((selectOptions, zone) => {
          const now = spacetime.now(zone[0]);
          const tz = now.timezone();
          const tzStrings = soft(zone[0]);

          let label = '';
          const abbr = now.isDST()
            ? // @ts-expect-error -- blah blah
              tzStrings[0].daylight?.abbr
            : // @ts-expect-error -- blah blah
              tzStrings[0].standard?.abbr;
          const altName = now.isDST()
            ? tzStrings[0].daylight?.name
            : tzStrings[0].standard?.name;

          const min = tz.current.offset * 60;
          const hr =
            `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
          const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;

          label = prefix;

          selectOptions.push({
            value: tz.name,
            label: label,
            offset: tz.current.offset,
            abbrev: abbr,
            altName: altName,
          });

          return selectOptions;
        }, [])
        .sort((a: Timezone, b: Timezone) =>
          a.offset && b.offset ? a.offset - b.offset : -1
        )
    );
  }, []);

  useEffect(() => {
    if (!selectedTimezoneCached) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log({ userTimezone });
      if (userTimezone) {
        setSelectedTimezoneCached(userTimezone);
      }
    }
  }, [selectedTimezoneCached, setSelectedTimezoneCached]);

  const setTimezone = useCallback(
    (timezone: Timezone | string) => {
      const updateValue =
        typeof timezone === 'string' ? timezone : timezone?.value;
      setSelectedTimezoneCached(updateValue);
    },
    [setSelectedTimezoneCached]
  );

  const selectedTimezone = useMemo(() => {
    return parseTimezone(
      selectedTimezoneCached ||
        Intl.DateTimeFormat().resolvedOptions().timeZone ||
        'Etc/GMT'
    ) as Timezone;
  }, [parseTimezone, selectedTimezoneCached]);

  const contextValue = useMemo(
    () => ({
      timezones,
      selectedTimezone,
      setTimezone,
    }),
    [selectedTimezone, setTimezone, timezones]
  );

  return (
    <TimezoneContext.Provider value={contextValue}>
      {children}
    </TimezoneContext.Provider>
  );
};

export const useTimezoneContext = (): TimezoneContextType => {
  const context = useContext(TimezoneContext);

  if (!context) {
    return {
      timezones: [],
      selectedTimezone: undefined,
      setTimezone: () => {},
    };
  }

  return context;
};
